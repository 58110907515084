'use strict';

Gri.module({
  name: 'group-icons-beta',
  ieVersion: null,
  $el: $('.group-icons-beta'),
  container: '.group-icons-beta',
  fn: function () {
    
  }
});
